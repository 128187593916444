<template>
	<div class="details">
		<div class="contains">
			<div class="con_top">
                <div class="con_top_left">
				    <img style="width:360px;height:360px" src="@/assets/images/ceping/400 400.png">
                </div>
				<div class="con_top_right">
					<h2>自测评风险评估</h2>
					<p class="new_tit">公司网络安全自评估，企业自己出于周期性的信息安全评估需要，亦或者是网站需要办理增值电信业务许可证（EDI许可证/ICP许可证）时需要提供网络安全防护自评估报告</p>
                    <div class="pri_box">
					    <p class="title">产品价格<i>￥</i><span class="price">在线咨询</span></p>
                    </div>
                    <div class="price_box">
                        <div class="box_left">
                            <p>说</p><p>明</p>
                        </div>
                        <div class="box_right">
                            <p>
                                公司网络安全自评估，企业自己出于周期性的信息安全评估需要，亦或者是网站需要办理增值电信业务许可证（EDI许可证/ICP许可证）时需要提供网络安全防护自评估报告
                            </p>
                        </div>
					</div>
				
					<div class="box_ti">
                        <div class="ti_left">
                            <p>数</p><p>量</p>
                        </div>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>
			</div>
		</div>
		<div class="conten_body">  
			<div class="conten_left">
			
                <div class="background">
                    <div class="title">
                        <p>服务背景</p>
                    </div>
                    <div class="background_content">
                        <ul>
                            <li>1.系统、网站或者APP因上级主管部门（如金融局、网信办、通管局等政府部门）监管需要提供信息系统的安全评估报告。</li>
                            <li>2.公司自建的系统、网站或者App,在投入使用或者交付客户前，需要了解该系统的安全状况等。</li>
                        </ul>
                    </div>
                    <div class="background_img">
                        <img src="@/assets/images/ceping/01.png" alt="">
                    </div>
                </div>

                <div class="substance">
                    <div class="title">
                        <p>服务内容</p>
                    </div>
                    <div class="substance_img">
                        <img src="@/assets/images/ceping/02.jpg" alt="">
                    </div>
                </div>

                <div class="flow">
                    <div class="title">
                        <p>服务流程</p>
                    </div>
                    <div class="flow_img">
                        <img src="@/assets/images/ceping/03.jpg" alt="">
                    </div>
                    <div class="flow_lis">
                        <ul>
                            <li>GB/T 20269_2006《信息安全技术 信息系统安全管理要求》</li>
                            <li>YD/T 3169_2016《互联网新技术新技术新业务新安全评估指南》</li>
                            <li>YDT 1730_2008《电信网和互联网安全风险评估实施指南》</li>
                            <li>GB/T 20984_2007《信息安全技术信息安全风险评估规范》</li>
                            <li>YD/T 1729_2008《电信网和互联网安全等级保护实施指南》</li>
                            <li>YDB 106_2012《增值电信业务系统安全防护定级和评测实施规范-门户综合网站系统》</li>
                            <li>YDB 107_2012《增值电信业务系统安全防护定级和评测实施规范-即时通信系统》</li>
                            <li>YDB 108_2012《增值电信业务系统安全防护定级和评测实施规范-网络交易系统》</li>
                            <li>YDB 109_2012《增值电信业务系统安全防护定级和评测实施规范-信息社区服务系统》</li>
                            <li>YDB 110_2012《增值电信业务系统安全防护定级和评测实施规范-邮件系统》</li>
                            <li>YDB 111_2012《增值电信业务系统安全防护定级和评测实施规范-搜索系统》</li>
                            <li>YDB 112_2012《增值电信业务系统安全防护定级和评测实施规范-互联网接入服务系统》</li>
                            <li>YDB 113_2012《域名服务系统安全防护定级和评测实施规范》</li>
                            <li>YDB 114_2012《互联网内容分发网络安全防护要求》</li>
                            <li>YDB 115_2012《互联网内容分发网络安全防护检测要求》</li>
                            <li>YDB 116_2012《互联网数据中心安全防护要求》</li>
                            <li>YDB 117_2012《互联网数据中心安全防护检测要求》</li>
                        </ul>
                    </div>
                </div>


                <div class="anli">
                    <div class="title">
                        <p>案例介绍</p>
                    </div>
                    <div class="anli_img">
                        <img src="@/assets/images/ceping/04.jpg" alt="">
                    </div>
                </div>


                <div class="advantage">
                   <div class="title">
                        <p>我们的优势</p>
                    </div>
                    <div class="advantage_content">
                        <div class="content">
                            <div class="content_left">
                                <ul>
                                   <li>1.国 家信息安全服务资质(安全工程类)</li>
                                   <li>2.国家信息安全风险评估资质</li>
                                   <li>3.国家通信网络安全服务风险评估资质</li>
                                   <li>4.国家信息安全应急处理服务资质</li>
                                   <li>5.国家信息系统安全集成服务资质</li>
                                   <li>6.国家信息安全漏洞库支持单位</li>
                                   <li>7.国际信息安全管理体系ISO 27001认证</li>
                                   <li>8.国际质量管理体系ISO9001认证</li>
                                   <li>9.国际环境管理体系ISO 14001认证</li>
                                   <li>10.高新技术企业</li>
                                   <li>11.软件企业</li>
                                   <li>12.企业研究开发机构证书</li>
                                </ul>
                            </div>
                            <div class="content_right">
                                <img src="@/assets/images/app/qualifications.jpg" alt="">
                            </div>
                        </div>
                        <div class="content_bottom">
                            <ul>
                               <li>1.安全博士领衔多位获得国际和国内安全资质的安全专家为您服务。</li>
                               <li>2.高效的等保通过率，帮助客户在最短的时间最高的质量最低的价格通过等保测评认证。</li>
                               <li>3.稳定的合作关系：与众多测评机构建立稳定的合作关系，深刻理解测评重点，帮助客户省时省力通过等保合规。</li>
                               <li>4.公司资质：具备国家网络信息安全主管部门颁发的权威安全认证资质及国际权威认证资质。</li>
                            </ul>
                        </div>
                    </div>
                </div>


				
            
        



				

			</div>
			<!-- right -->
			<div class="conten_right">
                <div class="phone">
                    <div class="phone_left">
                        <p>服务热线(微信同号)：</p>
                        <p>188 188 60088</p> 
                        <p>188 188 63388</p>
                    </div>
                    <div class="phone_right">
                        <img src="@/assets/images/app/code.png" alt="">
                    </div>
                </div>
                <div class="hot">
                    <div class="hot_title">
                        <h4>热门产品</h4>
                    </div>
                        <div class="hot_lists">
                            <ul>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/guangdong">
                                            <img src="@/assets/images/record/guangdong.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>广东省增值电信业务</p>
                                        <p>1、服务团队从业经...</p>
                                        <p>￥8000-40000</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/ability">
                                            <img src="@/assets/images/app/007.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>APP安全能力第三方评估报告</p>
                                        <p>App、安全评估报告...</p>
                                        <p>￥9800</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
			
		</div>
	</div>
</template>

<script type="text/javascript">
export default{

data(){
	return{
		transmitData:{
			title:"云上业务安全无忧保障计划初级套餐",
			content:"",
			price:'0',
			url:require('@/assets/images/yunsafe/222.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}

.background{
    .title{
        width: 100%;
        background-color: #007CC6;
        p{
            font-weight: bold;
            font-size: 30px;
            line-height: 83px;
            color: white;
            text-align: center;
        }
    }
    .background_content{
        padding: 10px 20px;
        ul{
            li{
                font-size: 18px;
                line-height: 20px;
                margin-top: 10px;
            }
        }
    }
    .background_img{
        width: 555px;
        height: 373px;
        margin: 10px auto;
        img{
            width: 100%;
            height: 100%;
        }
    }
}

.substance{
    .title{
        width: 100%;
        background-color: #007CC6;
        p{
            font-weight: bold;
            font-size: 30px;
            line-height: 83px;
            color: white;
            text-align: center;
        }
    }
    .substance_img{
        width: 873px;
        img{
            width: 100%;
            height: 100%;
        }
    }
}

.flow{
    .title{
        width: 100%;
        background-color: #007CC6;
        p{
            font-weight: bold;
            font-size: 30px;
            line-height: 83px;
            color: white;
            text-align: center;
        }
    }
    .flow_img{
        width: 873px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .flow_lis{
        margin: 30px 60px;
        ul{
            li{
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}

.anli{
    .title{
        width: 100%;
        background-color: #007CC6;
        p{
            font-weight: bold;
            font-size: 30px;
            line-height: 83px;
            color: white;
            text-align: center;
        }
    }
    .anli_img{
        width: 873px;
        img{
            width: 100%;
            height: 100%;
        }
    }
}

.advantage{
    .title{
        width: 100%;
        background-color: #007CC6;
        p{
            font-weight: bold;
            font-size: 30px;
            line-height: 83px;
            color: white;
            text-align: center;
        }
    }
    .advantage_content{
        margin-top: 40px;
        .content{
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            .content_left{
                ul{
                    li{
                        font-size: 20px;
                        line-height: 20px;
                        margin-bottom: 5px;
                    }
                }
            }
            .content_right{
                width: 313px;
                height: 296px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .content_bottom{
            margin-top: 30px;
            border: 1px solid #007CC6;
            border-radius: 10px;
            ul{
                padding: 15px 10px;
                li{
                    font-size: 21px;
                    line-height: 28px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}










.conten_right{
	width:300px;
	background:#f4f4f4;
	float:right;
  display: block;
  min-height: 100%;
	margin-left:8px;
    .phone{
        width: 100%;
        height: 100px;
        background-color: #00A9F2;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .phone_left{
            p{
                font-size: 16px;
                line-height: 18px;
                color: white;
            }
        }
        .phone_right{
            width: 75px;
            height: 75px;
            img{
                width:100%;
                height: 100%;
                }
        }
    }
    .hot{
        margin-top: 30px;
        background-color: #F2F2F2;
        height: 380px;
        .hot_title{
            width: 300px;
            height: 35px;
            background: #00A9F2;
            h4{
                margin-left: 20px;
                font-size: 18px;
                line-height: 35px;
                color: white;
            }
        }
        .hot_lists{
            ul{
                li{
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 50px;
                    .hot_left{
                        width: 147px;
                        height: 116px;
                        img{
                            width:147px;
                            height:116px;
                        }
                    }
                    .hot_right{
                        width: 140px;
                        margin-left: 20px;
                        p{
                            font-size: 14px;
                            line-height: 25px;
                             white-space: nowrap;    
                            overflow: hidden;         
                            text-overflow: ellipsis;
                        }
                        :nth-child(1){
                            padding-right: 25px;
                            font-weight: bold;
                           
                        }
                        :nth-child(2){
                             padding-right: 48px;
                            font-size: 13px;
                             
                        }
                         :nth-child(3){
                            font-size: 16px;
                            color: #B20909;
                           
                        }
                    }
                }
            }
        }
    }

}

.contains{
    margin-top: 20px;
	padding:20px;
	background: #fff;
}
.con_top{
    display: flex;
	overflow:hidden;
}
.con_top_left{
	width:358px;
    margin-right: 75px;
}

.con_top_right{
	width:790px;
}
.con_top_right h2{
	font-size: 25px;
	color: #000000;
    font-weight: bold;
	line-height: 50px;
}
.new_tit{
	background-color:#00A9F2;
	line-height: 18px;
	font-size:16px;
    padding:10px;
	color:#fff;
}
.pri_box{
	// background:url('../assets/images/b03.png');
	padding-top:20px;
	box-sizing:border-box;
}
.pri_box .title{
    font-size: 18px;
    color: #000000;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #C10909;
	line-height: 47px;
    padding-left: 45px;
    padding-right: 10px;
}
.pri_box  .price{
	font-size: 24px;
	font-weight: bold;
	color: #C10909;
	position: relative;

}
.price_box{
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    .box_left{
        width: 105px;
        height: 24px;
        padding-right: 15px;
        display: flex;
        justify-content: space-between;
        p{
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            color: #000000; 
            width: 64px;

        }
    }
    .box_right{
        p{
            font-size: 16px;
            line-height: 18px;
        }
    }
}
.box_ti{
   margin-top:25px;
   display: flex;
   align-items: flex-start;
}
.box_ti .ti_left{
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-right: 50px;
}
.ti_left p{
    font-size: 18px;
	color: #000000;
	line-height: 18px;
}
.box_ti .numbers{
    width: 69px;
	height: 25px;
	border: 1px solid #000000;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	line-height: 30px;
    padding-left: 6px;
}
.box_bottom{
	margin-top:25px;
	
}
.box_bottom a{
	width: 162px;
	height: 47px;
	background: #D97602;
	font-size: 20px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 47px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}
.details{
	width:1200px;
	margin:0 auto;

//**/





}


</style>







